import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './http/http'
import 'element-ui/lib/theme-chalk/index.css'
import 'lib-flexible/flexible.js'
import url from '@/utils/url'
import './assets/css/common.css'
import './assets/css/globe.css'
import './assets/fonts/iconfont.css'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

//本地测试
// Vue.prototype.BASE_URL = 'http://192.168.1.200:8888'
// 宝鸡
// Vue.prototype.BASE_URL = 'https://baoji.dazhihui029.com:8567'
//渭南
// Vue.prototype.BASE_URL = 'https://weinan.dazhihui029.com:8533'
// 西安
// Vue.prototype.BASE_URL = 'https://xian.dazhihui029.com:8700'
// 咸阳
Vue.prototype.BASE_URL = 'https://xianyang.dazhihui029.com:8703'

Vue.prototype.$url = url

Vue.config.productionTip = false

// 路由导航守卫
router.beforeEach((to, from, next)=>{
  if (to.path === '/DownLoad') return next()
  if (to.path === '/Login') return next()
  const tokenStr = window.localStorage.getItem('token')
  if (!tokenStr) return next('/Login')
  next()
})

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
