import axios from 'axios'
import router from '../router/index'
import { Message, Loading } from 'element-ui'

axios.defaults.withCredentials = true
axios.defaults.timeout = 30000

let loading

// 本地测试
// axios.defaults.baseURL = 'http://192.168.1.200:8888'
//宝鸡
// axios.defaults.baseURL = 'https://baoji.dazhihui029.com:8567'
//渭南
// axios.defaults.baseURL = 'https://weinan.dazhihui029.com:8533'
// // 西安
// axios.defaults.baseURL = 'https://xian.dazhihui029.com:8700'
//咸阳
axios.defaults.baseURL = 'https://xianyang.dazhihui029.com:8703'

// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('token')
  config.headers.Loginsource = '1'
  if (window.localStorage.getItem('loginInfo') !== null && window.localStorage.getItem('loginInfo') !== '') {
    config.headers.Anotherplace = JSON.parse(window.localStorage.getItem('loginInfo')).anotherplace
  }
  if (config.showLoading === true) {
    startLoading()
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(function (response) {
  if (response.config.showLoading) {
    endLoading()
  }
  if (response.data.status === '3') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
      window.localStorage.setItem('loginInfo', '')
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('tabActive', '')
      window.sessionStorage.setItem('LoginState', '0')
    }, 1000)
  }
  if (response.data.status === '2') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
      window.localStorage.setItem('loginInfo', '')
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('tabActive', '')
      window.sessionStorage.setItem('LoginState', '0')
    }, 1000)
  }
  return response
}, function (error) {
  if (error.response) {
    Message.error('服务器繁忙！')
    // if (error.response.status === 403) {
    //   Message.error('服务器繁忙')
    // } else if (error.response.status === 404) {
    //   Message.error('请求错误')
    // } else if (error.response.status === 500) {
    //   Message.error('服务器错误')
    // } else if (error.response.status >= 500) {
    //   Message.error('服务器异常')
    // }
  }
  return Promise.reject(error)
})

function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '加载中',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
function endLoading () {
  loading.close()
}

// get请求
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      showLoading: true
    },{ header: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' } }).then(res => {
      resolve(res.data)
    }).catch(err => {
      endLoading()
      reject(err.data)
      // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}

// post请求
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params, { showLoading: true })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        endLoading()
        reject(err.data)
        // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// put请求
export function put (url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
        // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// delete 请求
export function deletefn (url, params) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: params,
      showLoading: true
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
      // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}
